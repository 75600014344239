$(document).ready(function () {
  'use strict';

  /*
   *
   * VIRTUAL HOUSE
   *
   *
   */


  $('.prj--vh-nav a').click(function(e) {

    e.preventDefault();

    $('.prj--vh-nav a').removeClass('prj--is-active');
    $(this).addClass('prj--is-active');

    var house = $(this).data('house');
    var slide = $(this).data('slide');

    console.log(slide);

    $(this).parents(".prj--vh").find(".slick-slider").slick("slickGoTo", slide);

  });


  // Klick auf Marker

  $('.prj--vh-map-marker .prj--vh-map-marker-item-trigger').click(function() {


    // Checkt ob auf einen geöffneten Button geklickt wird

    var isOpened = false;

    if($(this).hasClass('prj--is-active'))
    {
      isOpened = true;
    }


    // Reset

    $('.prj--vh-map-marker-item-trigger').removeClass('prj--is-equal prj--is-active');
    $('.prj--vh-map-marker-item-content').fadeOut(200);


    if(isOpened)
    {
      return;
    }


    // Aktiver Punkt

    var mapItem = $(this).parent().data('marker');


    // Errechnet Y-Verschiebung der Content-Box

    var contentHeight = $(this).parent().find('.prj--vh-map-marker-item-content').outerHeight();
    var contentPosition = (contentHeight/2)-contentHeight;


    // Markiert gleiche Marker

    $('[data-marker="'+mapItem+'"] .prj--vh-map-marker-item-trigger').toggleClass('prj--is-equal');


    // Zeigt geklickten Marker

    $(this).addClass('prj--is-active');
    $(this).parent().find('.prj--vh-map-marker-item-content').css({'margin-top': contentPosition+'px'}).fadeIn(250);

  });


  // Klick auf Legenden-Opener

  $('.prj--vh-map-nav-btn').click(function(e) {

    e.preventDefault();

    $(this).parent().find('ul').fadeToggle(200);

  });


  // Klick auf Legenden-Element

  $('.prj--vh-map-nav [data-marker]').click(function(e) {

    e.preventDefault();

    var mapItem = $(this).data('marker');

    $('[data-marker="'+mapItem+'"] .prj--vh-map-marker-item-trigger').addClass('prj--is-pulse');
    setTimeout(function(){
      $('[data-marker="'+mapItem+'"] .prj--vh-map-marker-item-trigger').removeClass('prj--is-pulse');
    }, 500);

  });


});
