$(document).ready(function () {
  'use strict';


  /*
   *
   * SETUP SECTIONS VOR ANCHOR MENU
   *
   *
   */

  setupSectionsMenu();


  /*
   *
   * SETUP SECTIONS VOR ANCHOR MENU
   *
   *
   */

  $('.prj--anchor-nav .prj--show-nav').click(function(e) {
    e.preventDefault();
    $(this).parent().find('.prj--anchor-nav-list-wrap').fadeToggle();
  });


  /*
   *
   * ANCHOR LINKS SMOOTH SCROLL
   *
   *
   */

  $('.prj--anchor-link').stop().clearQueue().click(function(e) {
    e.preventDefault();

    var distance = $($(this).attr('href')).offset().top;
    var navHeight = $('#nav').outerHeight();

    $('html, body').animate({
      scrollTop: (distance-navHeight)
    }, 700);
  });


  /*
   *
   * MMENU INIT
   *
   *
   */


  $('#nav-main').mmenu({}, {
    clone: true
  });



  $('.prj--story-ajax').click(function(e) {

    e.preventDefault();


    // Loading Animation
    var icon = $(this).find('.fa');
    icon.addClass('prj--icon-spin fa-spin');


    // Leere Content bevor .load damit kein Blinky-Effekt entsteht
    $('#story-box .prj--append-share-links').empty();
    $('#story-box .uk-modal-content').empty();


    // Variablen
    var href = $(this).attr("href");
    var load = href + ' #prj--story-load';


    // Zur Seite Buttons werden befüllt
    $("#story-box .prj--story-link").attr('href', href);


    // Lade Inhalt nach
    $('#story-box .uk-modal-content').load(load, function(){

      var modal = UIkit.modal("#story-box");

      modal.show();
      icon.removeClass('prj--icon-spin fa-spin');


      // WICHTIG nach modal.show, damit Modal nicht automatisch ganz nach unten scrollt

      // Share Buttons
      var shareMail     = 'mailto:?body=' + href;
      var shareWhatsapp = 'whatsapp://send?text=' + href;

      setTimeout(function() {
        $('#story-box .prj--append-share-links').append('&nbsp;<a data-uk-tooltip title="via E-Mail" class="prj--share-email" href="'+shareMail+'"><i class="fa fa-envelope"></i></a>');
        $('#story-box .prj--append-share-links').append('&nbsp;<a data-uk-tooltip title="via WhatsApp" class="prj--share-whatsapp uk-hidden-notouch" href="'+shareWhatsapp+'"><i class="fa fa-whatsapp"></i></a>');
      }, 1000);
    });

  });



  /*
   *
   * SLICK INIT
   *
   *
   */

  $('.slick-slider').slick({
    dots: false,
    arrows: true,
    autoplay: true,
    cssEase: 'ease-out',
    autoplaySpeed: 7000,
    speed: 500
  });


});




/*
 *
 * SLUGIFY FUNCTION
 *
 *
 */

function slugify(text)
{
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}


function setupSectionsMenu()
{

  $('#nav .current-menu-item').append('<ul class="prj--anchor-nav-list"></ul>');

  // Array für alle Sections
  var sections = [];

  // Loop Sections
  $('.prj--is-section').each(function(e) {

    // Section Name
    var name = $(this).data('section');

    if(name.slice(-2) == ' -')
    {
      name = name.substr(0, name.length-2);
    }


    // Section Name slugified into ID
    $(this).attr('id', slugify(name));

    // Write Section into SectionArray
    sections.push(name);

    // Write new Link into Anchor-Nav
    $('.prj--anchor-nav-list').append('<li><a class="prj--anchor-link" href="#'+slugify(name)+'">' + name + '</a></li>');

  });


  // Sow Anchor-Nav when entries are available

  if(sections.length > 0)
  {
    $('.prj--anchor-nav').show();
  }
  else
  {
    $('.prj--anchor-nav').hide();
  }


  // Run Menu Scroller
  sectionMenuScroller();


}



/*
 *  Menu Scroller
 *
 */

function sectionMenuScroller() {


  /*
   *  Define Nav Elements / Sections
   *
   */

  var aChildren = $(".prj--anchor-nav-list li:not(.prj--is-headline)").children(); // find the a children of the list items
  var aArray = []; // create the empty aArray
  for (var i=0; i < aChildren.length; i++)
  {
    var aChild = aChildren[i];
    var ahref = $(aChild).attr('href');
    aArray.push(ahref);
  } // this for loop fills the aArray with attribute href values



  /*
   *  Scroll Events
   *
   */

  $(window).scroll(function() {


    /*
     *  Automatic Scroll-Nav-Update
     *
     */


    var windowPos = $(window).scrollTop()+$("#nav").outerHeight() + 2; // get the offset of the window from the top of page
    var windowHeight = $(window).height(); // get the height of the window
    var docHeight = $(document).height();

    for (var i=0; i < aArray.length; i++) {

      if(aArray[i] !== "#")
      {

        var theID = aArray[i];
        var divPos = $(theID).offset().top; // get the offset of the div from the top of page
        var divHeight = $(theID).outerHeight(); // get the height of the div in question

        if (windowPos >= divPos && windowPos < (divPos + divHeight))
        {
          $(".prj--anchor-nav-list a[href='" + theID + "']").addClass("prj--is-active");
        }
        else
        {
          $(".prj--anchor-nav-list a[href='" + theID + "']").removeClass("prj--is-active");
        }

      }

    }

    if(windowPos + windowHeight == docHeight) {
      if (!$(".prj--anchor-nav-list li:last-child a").hasClass("prj--is-active")) {
        var navActiveCurrent = $(".prj--is-active").attr("href");
        $(".prj--anchor-nav-list a[href='" + navActiveCurrent + "']").removeClass("prj--is-active");
        $(".prj--anchor-nav-list li:last-child a").addClass("prj--is-active");
      }
    }


  });


}
